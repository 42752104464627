import React from 'react'
import { navigate } from 'gatsby-link'
import Layout from '../../components/LayoutRU'

function encode(data) {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&')
}

export default class IndexRU extends React.Component {
  constructor(props) {
    super(props)
    this.state = { isValidated: false }
  }

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value })
  }

  handleSubmit = e => {
    e.preventDefault()
    const form = e.target
    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({
        'form-name': form.getAttribute('name'),
        ...this.state,
      }),
    })
      .then(() => navigate(form.getAttribute('action')))
      .catch(error => alert(error))
  }

  render() {
    return (
      <Layout>
        <section className="section">
          <div className="container">

            <div className="content">
              <div className="grid-containerKontaktowy">
                <div className="formularzkontaktowy">
                  <h1>Контактная форма</h1><form
                  name="contact"
                  action="https://formspree.io/mbjgkoeo"
                  method="POST"
                >
                  {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
                  <input type="hidden" name="form-name" value="contact" />
                  <div hidden>
                    <label>
                      Don’t fill this out:{' '}
                      <input name="bot-field" onChange={this.handleChange} />
                    </label>
                  </div>
                  <div className="field">
                    <label className="label" htmlFor={'name'}>
                      Ваше имя
                  </label>
                    <div className="control">
                      <input
                        className="input"
                        type={'text'}
                        name={'name'}
                        onChange={this.handleChange}
                        id={'name'}
                        required={true}
                      />
                    </div>
                  </div>
                  <div className="field">
                    <label className="label" htmlFor={'email'}>
                      Email
                  </label>
                    <div className="control">
                      <input
                        className="input"
                        onChange={this.handleChange}
                        id={'email'}
                        required={true}
                        type="text" name="_replyto"
                      />
                    </div>
                  </div>
                  <div className="field">
                    <label className="label" htmlFor={'message'}>
                      Сообщение
                  </label>
                    <div className="control">
                      <textarea
                        className="textarea"

                        onChange={this.handleChange}
                        id={'message'}
                        required={true}
                        name="message"
                      />
                    </div>
                  </div>
                  <div className="field">
                    <button className="button is-link" type="submit">
                      Отправить
                  </button>
                  </div>
                </form></div>
                <div className="kontakt"><h1>Контакты</h1>
                  <p>
                    Ekochron<br></br>
                    43-426 Дембовец Огродзона, ул. Przemysłowa  15<br></br>
                    Силезское воеводство, Польша<br></br>
                    GPS: N 49,768314 E 18,723047   <br></br><br></br>
                    тел. 33 857 90 10, 33 853 32 91
                    </p>

                  <p className="kontaktH5">Отдел обслуживания Клиентов:</p>
                  <p> тел. 33 857 90 10 вн. 21, 26<br></br>
                    <a href="mailto:sprzedaz@ekochron.pl">e-mail: sprzedaz@ekochron.pl</a></p>
                  <p className="kontaktH5"> Технический консалтинг:</p>
                  <p> тел. 33 857 90 10 вн. 17<br></br>
                    <a href="mailto:doradca@ekochron.pl">e-mail: doradca@ekochron.pl</a></p>

                </div>
                <div className="jakzamawiac">
                  <h1>Как заказать?</h1>
                  <p>Продукцию марки «Экохрон» можно заказать несколькими способами:<br></br>
                    📞🠞по телефону <b>+48 33 857 90 10</b>, <b>+48 33 853 32 91</b> внутренний 21, 26;<br></br>
                    📧🠞по электронной почте: sprzedaz@ekochron.pl;<br></br>
                    📝🠞с помощью размещенной ниже контактной формы или лично в офисе компании.<br></br>
                    </p>
                  <ul>
                    <li>Коммерческий отдел работает с понедельника по пятницу с 7.00 до 15.00.</li>
                    <li>Заказ товара равнозначен его бронированию.</li>
                    <li>В случае отсутствия изделия на складе мы сообщаем конкретную дату готовности заказа, о которой уведомим клиента по телефону или по электронной почте.</li>
                    <li>Время исполнения заказа составляет до 5 рабочих дней, а в случае нестандартных изделий – не более 3 недель.</li>
                  </ul>


                </div>
              </div>


            </div>
          </div>
        </section>
      </Layout>
    )
  }
}
